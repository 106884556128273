import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, EmailValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@shared/myservice/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  reactiveForm: FormGroup;
  showPassword:boolean= false;
  internalServerError: boolean = false;
  le_role: string;
  encpassword : string="testpassword"
  encryptedData: string
  errorMessage: string;
  error: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  get type() {
    return this.reactiveForm.get('type');
  }
  get username() {
    return this.reactiveForm.get('username');
  }
  get is_confim() {
    return this.reactiveForm.get('is_confim');
  }
  get password() {
    return this.reactiveForm.get('password');
  }


msg=""
  constructor(private fb: FormBuilder, private router: Router, private authService:AuthService) {
    this.reactiveForm = this.fb.group({
      username : ['', [Validators.required,Validators.pattern(this.emailPattern)]],
      is_confirm: ['false'],
      password: ['', [Validators.required]],
      is_active: [true, [Validators.required]],
      is_superuser: ['false', [Validators.required]],
      id: [0, [Validators.required]],
    });
  }

  ngOnInit() {
this.initializeFormGroup();
  }


  initializeFormGroup() {
    this.reactiveForm.setValue({
      id: 0,
      username: '',
      password: '',
      is_active:'',
      is_superuser:'',
      is_confirm:'',

    });
  }


  get passwordToggleLabel() {
    return this.showPassword ? 'Hide password' : 'Reveal password';
  }

  get passwordToggleIcon() {
    return this.showPassword ? 'visibility' : 'visibility_off';
  }
  get passwordType() {
    return this.showPassword ? 'text' : 'password';
  }




   login() {
    const formData = this.reactiveForm.value;
    if (this.authService.check(this.reactiveForm.get('username').value,this.reactiveForm.get('password').value )){
      this.router.navigate(["/dashboard"])
    }else{
      this.router.navigateByUrl('/auth/login');
      this.msg="L'email ou le mot de passe est incorrect"
    }
//

  }




  /* encrypter() {

    this.encryptedData = CryptoJS.AES.encrypt(this.le_role.trim(), this.encpassword.trim()).toString();

    console.log(this.encryptedData)

    return this.encryptedData
}  */
}
