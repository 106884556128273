import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DialogService } from '@shared/myservice/dialog.service';
import { MessageService } from '@shared/myservice/message.service';
import { UtilisateurService } from '@shared/myservice/utilisateur.service';
import { Utilisateur } from 'app/models/model.utilisateur';
import { ToastrService } from 'ngx-toastr';
import { MessageComponent } from '../message/message.component';

@Component({
  selector: 'app-contact-message',
  templateUrl: './contact-message.component.html',
  styleUrls: ['./contact-message.component.css']
})
export class ContactMessageComponent implements OnInit {
  utilisateurs: { id: number; selectionne: boolean; nom: string; prenom: string; email: string; sexe: string; ville: string; role: string; mobile: string; }[];
 list_email_user:Array<number>=[]
 list_id_user:Array<number>=[]
  constructor(private dialogService: DialogService, private messageService:MessageService, private toastr:ToastrService, private utilisateurService:UtilisateurService, private dialog: MatDialog,) { }
  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['selectionne', 'nom','prenom', 'ville', 'email']
  @ViewChild(MatSort, {static:true}) sort: MatSort;
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  searchKey: string;
  selection = new SelectionModel<Utilisateur>(true, []);
  ngOnInit() {
    this.getUtilisateur();
  }
  getUtilisateur() {
    this.utilisateurService.getUtilisateurActif().subscribe(
      (list: any) => {
        let listContact = list.map(item => {item.visible==true

          return {
            id: item.id,
            nom: item.nom,
            prenom:item.prenom,
            ville:item.ville,
            email: item.email,
            phone:item.phone
          };
        });
        setTimeout(() => {
          this.listData = new MatTableDataSource(listContact);
          this.listData.sort = this.sort;
          this.listData.paginator = this.paginator;
        }, 2000)
      },
    );

  }
  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  onCreate(){
    this.logSelection()
    this.messageService.initializeFormGroup();
    this.messageService.users.setValue(this.list_id_user);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose=true;
    dialogConfig.autoFocus=true;
    dialogConfig.minWidth="62%";
    dialogConfig.height="100%";

    this.dialog.open(MessageComponent,dialogConfig);
  }



  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.listData.data.forEach(row => this.selection.select(row));
  }

  logSelection() {
    this.list_id_user=[]
    this.selection.selected.forEach(s => {

     this.list_id_user.push(s.id)

    });
    console.log( this.list_id_user);

  }


}
