import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user',
  template: `
    <button
      mat-button
      class="matero-toolbar-button matero-avatar-button"
      href="javascript:void(0)"
      [matMenuTriggerFor]="menu"
    >

      <img class="matero-avatar" src="assets/images/logo_croix_mtge_grand.png" width="32" alt="avatar" />
      <span class="matero-username" fxHide.lt-sm>MTGE</span>
    </button>

    <mat-menu #menu="matMenu">
      <a (click)="logout()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </a>
    </mat-menu>
  `,
})
export class UserComponent {

  constructor(private router:Router){
    }

    logout(){
      localStorage.removeItem('email');
      this.router.navigate(['/auth/login']);



  }
}
