import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DemandeService } from '@shared/myservice/demande.service';
import { DialogService } from '@shared/myservice/dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inactif-demandes',
  templateUrl: './inactif-demandes.component.html',
  styleUrls: ['./inactif-demandes.component.css']
})
export class InactifDemandesComponent implements OnInit {


  listData: MatTableDataSource<any>;

  displayedColumns: string[] = ['user','adhesion','numero','sexe', 'ville', 'createdAt', 'actions']


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchKey: string;
  constructor(private demandeService:DemandeService, private dialogService: DialogService, private toastr:ToastrService) { }

  ngOnInit() {
    this.getInacitDemandes()
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  getInacitDemandes() {
    this.demandeService.getInacitDemandes().subscribe(
      (list: any) => {

        let cotisationList = list.map(item => {item.visible==true

          return {
            id: item.id,
            numero: item.phoneCode + ''+  item.phone,
            adhesion:item.adhesion,
            sexe:item.sexe?item.sexe:"Nom spécifié",
            ville: item.ville,
            createdAt:item.createdAt,
           user:item.nom +' '+item.prenom
          };
        });

        setTimeout(() => {
          this.listData = new MatTableDataSource(cotisationList);
          this.listData.sort = this.sort;
          this.listData.paginator = this.paginator;
        }, 2000)
      },
    );
  }



        onAccept($key){
          this.dialogService.openConfirmDialog('Êtes-vous sûr de vouloir accepter cette demande?')
               .afterClosed().subscribe(res =>{
                 if(res){
                   this.demandeService.acceptDemande($key).subscribe(
                     res=>{
                      this.toastr.info('Demande acceptée avec succès');
                      this.getInacitDemandes();

                        }
                   )

                 }
               });
              }

}
