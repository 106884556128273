import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment.prod';
import { ContactMessageComponent } from 'app/evenement/contact-message/contact-message.component';
import { GuardGuard } from 'app/guard.guard';
import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FaqComponent } from './faq/faq.component';
import { HistoriqueMessageComponent } from './historique-message/historique-message.component';
import { ListCotisationComponent } from './list-cotisation/list-cotisation.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
     canActivate:[GuardGuard],
    children: [
      { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard', titleI18n: 'dashboard' },
      },
      {
        path: 'sessions',
        loadChildren: () => import('./sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Sessions', titleI18n: 'Sessions' },
      },
      {
        path: 'demandes',
        loadChildren: () => import('./demandes/demandes.module').then(m => m.DemandesModule),
        data: { title: 'Gestion des demandes', titleI18n: 'demandes' },
      },
      {
        path: 'utilisateur',
        loadChildren: () => import('./sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Gestion des utilisateurs', titleI18n: 'utilisateur' },
      },
      {
        path: 'article',
        loadChildren: () => import('../article/article.module').then(m => m.ArticleModule),
        data: { title: 'Gestion des articles', titleI18n: 'article' },
      },
      {
        path: 'partenaires',
        loadChildren: () => import('./partenaires/partenaires.module').then(m => m.PartenairesModule),
        data: { title: 'Gestion des partenaires', titleI18n: 'partenaires' },
      },
      {
        path: 'sites',
        loadChildren: () => import('../sites/sites.module').then(m => m.SitesModule),
        data: { title: 'Gestion des partenaires', titleI18n: 'sites' },
      },
      {
        path: 'media',
        loadChildren: () => import('../media/media.module').then(m => m.MediaModule),
        data: { title: 'Médias', titleI18n: 'media' },
      },
      {
        path: 'evenement',
        loadChildren: () => import('../evenement/evenement.module').then(m => m.EvenementModule),
        data: { title: 'Médias', titleI18n: 'media' },
      },
      {
        path: 'contactutilisateur',
        component: ContactMessageComponent,
        data: { title: 'Message', titleI18n: 'Envoi de message' },
      },
      {
        path: 'cotisation',
        component: ListCotisationComponent,
        data: { title: 'Cotisation', titleI18n: 'cotisation' },
      },
      {
        path: 'historiquemessage',
        component: HistoriqueMessageComponent,
        data: { title: 'Historique d\'envoi de message', titleI18n: 'Historique d\'envoi de message' },
      },

    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login', titleI18n: 'Login' },
      },
      {
        path: 'register',
        component: RegisterComponent,
        data: { title: 'Register', titleI18n: 'Register' },
      },
    ],
  },
  { path: '**', redirectTo: 'login' },
  {
    path: 'cgu',
    component: FaqComponent,
    data: { title: 'FAQ', titleI18n: 'cgu' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
    }),
  ],

  exports: [RouterModule],
})
export class RoutesRoutingModule {}
