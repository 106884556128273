import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment.prod';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  le_role: any = "";


  constructor(public http: HttpClient, private route: ActivatedRoute) { }

  endpoint = environment.SERVER_URL;

isLoggin:boolean=false;

  getLoggingUser(): Observable<any> {

    return this.http.get(this.endpoint + '/auth/me/').pipe(catchError(this.errorHandling));

  }
  // private setSession(authResult) {

  //   const token = authResult.token;
  //   const payload = <JWTPayload>jwtDecode(token);
  //   const expiresAt = moment.unix(payload.exp);
  //   localStorage.setItem('token', authResult.token);
  //   localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  //   localStorage.setItem('le_role', this.le_role);

  // }
  get token(): string {
    return localStorage.getItem('token');
  }
 check(email, password){

if(email=="memoiretemplieregrandest@gmail.com" && password=="MTGE54"){
  localStorage.setItem('email', email)
  this.isLoggin=true;
  return true
}
return false
 }
 isLoggedIn() {
  return this.isLoggin
}

  /* public logIn(user: User){

    let headers = new Headers();
    headers.append('Accept', 'application/json')
    // creating base64 encoded String from user name and password
    /* var base64Credential: string = btoa( user.username+ ':' + user.password); */
  //headers.append("Authorization", "Basic ");

  /*  let options = new RequestOptions();
   options.headers=headers; */
  /*
  return this.http.post(AppComponent.API_URL+"compte/auth/token")
    .map((response: Response) => {
    // login successful if there's a jwt token in the response
    let token = response.json().token;// the returned user object is a principal object
    if (token) {
      // store user details  in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(token));
    }
  });
}
*/
//   login(email: string, password: string) {
//     return this.http.post(
//       this.endpoint.concat('/auth/token/'),
//       { email, password }
//     ).pipe(
//       tap(response => { this.setSession(response), localStorage.setItem('email', JSON.stringify(email)),

//       shareReplay(),


//       catchError(error => {
//         /* Provide a default fallback value ([]) to the subscribers,
//          despite the fact that the original Observable did error out.
//          So the error handling callback in subscribe() is not invoked anymore.
//          */
//         //return of([]);
//         /* --------------- */
//         /* Rethrow the error. */
//         return throwError(error);
//       })}))

// }

//   login(email: string, password: string) {
//     return this.http.post(
//       this.endpoint.concat('/auth/token/'),
//       { email, password }
//     ).pipe(
//       tap(response => { this.setSession(response), localStorage.setItem('email', JSON.stringify(email)),

//       shareReplay(),


//       catchError(error => {
//         /* Provide a default fallback value ([]) to the subscribers,
//          despite the fact that the original Observable did error out.
//          So the error handling callback in subscribe() is not invoked anymore.
//          */
//         //return of([]);
//         /* --------------- */
//         /* Rethrow the error. */
//         return throwError(error);
//       })}))

// }
  errorHandling(error: HttpErrorResponse) {
    return throwError(error);
  }
  logout(): Observable<any> {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('email');
    localStorage.removeItem('user_role');
    localStorage.removeItem('le_role');
    return this.http.get(this.endpoint + '/auth/logout/').pipe(catchError(this.errorHandling));



  }

  // refreshToken() {
  //   if (moment().isBetween(this.getExpiration().subtract(1, 'days'), this.getExpiration())) {
  //     return this.http.post(
  //       this.endpoint.concat('/auth/refresh-token/'),
  //       { token: this.token }
  //     ).pipe(
  //       tap(response => this.setSession(response)),
  //       shareReplay(),
  //     ).subscribe();
  //   }
  // }

  // getExpiration() {
  //   const expiration = localStorage.getItem('expires_at');
  //   const expiresAt = JSON.parse(expiration);

  //   return moment(expiresAt);
  // }

  // isLoggedIn() {
  //   return moment().isBefore(this.getExpiration());
  // }

l

  resetPassword(model: any) {
    return this.http.post(this.endpoint + "/password_reset/", model).pipe(catchError(this.errorHandling));
  }

  changePassword(model) {
    return this.http.post(this.endpoint + "/password_reset/confirm/", model).pipe(catchError(this.errorHandling));
  }
}
