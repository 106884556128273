import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class GuardGuard implements CanActivate {
 constructor(private router:Router){}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
    const emailExist= !!localStorage.getItem('email');
    if(emailExist){
      return true
    }else{

this.router.navigate(['auth/login'])
    }


  }

}
