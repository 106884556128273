import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DemandeService } from '@shared/myservice/demande.service';
import { DialogService } from '@shared/myservice/dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-attente-demandes',
  templateUrl: './attente-demandes.component.html',
  styleUrls: ['./attente-demandes.component.css']
})
export class AttenteDemandesComponent implements OnInit {

  listData: MatTableDataSource<any>;

  displayedColumns: string[] = ['user','adhesion','numero','sexe', 'ville', 'createdAt', 'actions']


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchKey: string;
  constructor(private demandeService:DemandeService, private dialogService: DialogService, private toastr:ToastrService) { }

  ngOnInit() {
    this.getAttenteDemande()
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  getAttenteDemande() {
    this.demandeService.getEnAttenteDemandes().subscribe(
      (list: any) => {

        let cotisationList = list.map(item => {item.visible==true

          return {
            id: item.id,
            numero: item.phoneCode + ''+  item.phone,
            adhesion:item.adhesion,
            sexe:item.sexe?item.sexe:"Nom spécifié",
            ville: item.ville,
            createdAt:item.createdAt,
           user:item.nom +' '+item.prenom
          };
        });

        setTimeout(() => {
          this.listData = new MatTableDataSource(cotisationList);
          this.listData.sort = this.sort;
          this.listData.paginator = this.paginator;
        }, 2000)
      },
    );
  }


  onRefuse($key){
    this.dialogService.openConfirmDialog('Êtes-vous sûr de vouloir rejeter cette demande?')
         .afterClosed().subscribe(res =>{
           if(res){
             this.demandeService.rejectdemande($key).subscribe(
               res=>{
                this.toastr.info('Demande rejetées avec succès');
                this.getAttenteDemande();

                  }
             )

           }
         });
        }
        onAccept($key){
          this.dialogService.openConfirmDialog('Êtes-vous sûr de vouloir accepter cette demande?')
               .afterClosed().subscribe(res =>{
                 if(res){
                   this.demandeService.acceptDemande($key).subscribe(
                     res=>{
                      this.toastr.info('Demande acceptée avec succès');
                      this.getAttenteDemande();

                        }
                   )

                 }
               });
              }


}
