import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MessageService } from '@shared/myservice/message.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit {
  destinataire;
  color = '#EF233C';
  typeMessage = [
    {
      id: 0,
      code: 'Notification',
    },
    { id: 1, code: 'Email' },
    { id: 2, code: 'Notification et email' },
  ];
  typeMedia = [
    {
      id: 'image',
      code: 'Photo',
    },
    { id: 'video', code: 'Vidéo' },
  ]; editorConfig1: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    outline: false,
    height: 'auto',
    minHeight: '10rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Rédigez votre article ici...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'roboto', name: 'Roboto' },
      { class: 'georgia', name: 'Georgia' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://localhost:8080/upload',

    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['toggleEditorMode']],
  };
  constructor(
    public messageService: MessageService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<MessageComponent>
  ) {}

  ngOnInit() {}

  onSubmit() {
    if (this.messageService.reactiveForm.valid) {
      this.messageService.insertMessage(this.messageService.reactiveForm.value).subscribe(res => {
        this.toastr.success('Message envoyé avec succès');
      });
    }

    this.onClose();
  }
  onClose() {
    this.messageService.reactiveForm.reset();
    this.messageService.initializeFormGroup();
    this.dialogRef.close();
  }
  onClear() {
    this.messageService.reactiveForm.reset();
    this.messageService.initializeFormGroup();
    this.toastr.warning('Fomulaire réinitialisé avec succès!');
  }
}
