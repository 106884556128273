import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttenteDemandesComponent } from './attente-demandes/attente-demandes.component';
import { InactifDemandesComponent } from './inactif-demandes/inactif-demandes.component';
import { ListDemandesComponent } from './list-demandes/list-demandes.component';



const routes: Routes = [



  {
    path: 'attentedemande',
    component: AttenteDemandesComponent,
    data: { title: 'Demandes en attente', titleI18n: 'Demandes en attente' },
  },

  {
    path: 'actifdemande',
    component: ListDemandesComponent,
    data: { title: 'Demandes actifs', titleI18n: 'Demandes actifs' },

  },
  {
    path: 'inactifdemande',
    component: InactifDemandesComponent,
    data: { title: 'Demande rejetées', titleI18n: 'Demande rejetées' },
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DemandesRoutingModule {}
