import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment.prod';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CotisationService {
  baseUrl: string = environment.SERVER_URL + '/dash';
  constructor(private _http:HttpClient) { }



  errorHandling(error: HttpErrorResponse) {
    return throwError(error);
  }


    getAllUtilisateurCotisation() {
      return this._http.get(this.baseUrl+"/cotisation/find").pipe(catchError(this.errorHandling));
         }

         getUtilisateurCotisation(idUtilisateur) {
          return this._http.get(this.baseUrl+"/cotisation/find-one?id="+idUtilisateur).pipe(catchError(this.errorHandling));
             }
}
