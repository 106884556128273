import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from '@env/environment.prod';
import { Departement } from 'app/models/departement.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DepartementService {
  reactiveForm: any;
  max_length = 100;

  baseUrl: string = environment.SERVER_URL + '/dash';
  get observation() {
    return this.reactiveForm.get('observation');
  }
  get nom() {
    return this.reactiveForm.get('nom');
  }
  get numero() {
    return this.reactiveForm.get('numero');
  }

  get id() {
    return this.reactiveForm.get('id');
  }

  constructor(private fb: FormBuilder, private _http: HttpClient) {
    this.reactiveForm = this.fb.group({
      id: [null],
      nom: ['', [Validators.required]],
      numero: ['', [Validators.required]],
      // email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      observation: [' ', [Validators.required]],
    });
  }
  initializeFormGroup() {
    this.reactiveForm.setValue({
      id: 0,
      nom: '',
      numero: '',
      observation: '',
    });
  }
  populateForm(row: any) {
    this.reactiveForm.patchValue({
      id: row.id,
      nom: row.nom,
      numero: row.numero,
      observation: row.observation,
    });
  }

  errorHandling(error: HttpErrorResponse) {
    return throwError(error);
  }
  getDepartementActif() {
    return this._http
      .get(this.baseUrl + '/departement/find-actif')
      .pipe(catchError(this.errorHandling));
  }

  getDepartementIactif() {
    return this._http
      .get(this.baseUrl + '/departement/find-inactif')
      .pipe(catchError(this.errorHandling));
  }

  insertDepartement(departement: Departement): Observable<any> {
    return this._http
      .post(this.baseUrl + '/departement/add', JSON.stringify(departement))

      .pipe(catchError(this.errorHandling));
  }

  updateDepartement(): Observable<any> {
    const formData = this.reactiveForm.value;
    return this._http
      .put<Departement>(this.baseUrl + '/departement/update', JSON.stringify(formData))
      .pipe(catchError(this.errorHandling));
  }
  delDepartement(idDepartement, visible) {

    const formData = new FormData();
    formData.append('id', idDepartement);
    formData.append('visible', visible);
    return this._http.post(this.baseUrl + '/departement/action', formData);
  }
}
