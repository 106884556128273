import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DemandeService {
  baseUrl: string = environment.SERVER_URL + '/dash';
  constructor(private _http:HttpClient) { }



  errorHandling(error: HttpErrorResponse) {
    return throwError(error);
  }


    getAcitDemandes() {
      return this._http.get(this.baseUrl+"/adhesion/accepter").pipe(catchError(this.errorHandling));
         }

         getInacitDemandes() {
          return this._http.get(this.baseUrl+"/adhesion/refuser").pipe(catchError(this.errorHandling));
             }

             getEnAttenteDemandes() {
              return this._http.get(this.baseUrl+"/adhesion/attente").pipe(catchError(this.errorHandling));
                 }

                rejectdemande(idDemande) {
                  const formData = new FormData();
                  formData.append('user', idDemande);
                  formData.append('action', "0");
                  return this._http.post(this.baseUrl + '/adhesion/action', formData);
                }

               acceptDemande(idDemande) {
                  const formData = new FormData();
                  formData.append('user', idDemande);
                  formData.append('action', "1");
                  return this._http.post(this.baseUrl + '/adhesion/action', formData);
                }

}


