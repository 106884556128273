import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from '@env/environment.prod';

import { Utilisateur } from 'app/models/model.utilisateur';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {
  baseUrl: string = environment.SERVER_URL+ "/dash";
  reactiveForm: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  passwordPatern="^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$";
  motcontrole: any;
utilisateurs=[{id:1, selectionne:false, nom:"AGBO", prenom:"Alain", email:"ago@gmail.com", sexe:"Masculin", ville:"Bohicon", role:"TRST",mobile:"96414944"},
{id:2, selectionne:false,  nom:"BAKO", prenom:"Paulin", email:"aliou@gmail.com", sexe:"Feminin", ville:"Calavi", role:"ADMIN",mobile:"96414944"},{id:1, selectionne:false, nom:"AGBO", prenom:"Alain", email:"ago@gmail.com", sexe:"Masculin", ville:"Bohicon", role:"TRST",mobile:"96414944"},
{id:2, selectionne:false,  nom:"BAKO", prenom:"Paulin", email:"aliou@gmail.com", sexe:"Feminin", ville:"Calavi", role:"ADMIN",mobile:"96414944"},{id:1, selectionne:false, nom:"AGBO", prenom:"Alain", email:"ago@gmail.com", sexe:"Masculin", ville:"Bohicon", role:"TRST",mobile:"96414944"},
{id:2, selectionne:false,  nom:"BAKO", prenom:"Paulin", email:"aliou@gmail.com", sexe:"Feminin", ville:"Calavi", role:"ADMIN",mobile:"96414944"}


]
get nom(){
  return this.reactiveForm.get('nom');
}
get prenom(){
  return this.reactiveForm.get('prenom');
}
get email(){
  return this.reactiveForm.get('email');
}
get phone(){
  return this.reactiveForm.get('phone');
}
get role(){
  return this.reactiveForm.get('role');
}

get phoneCode(){
  return this.reactiveForm.get('phoneCode');
}

  constructor(private fb:FormBuilder, private _http: HttpClient) {
    this.reactiveForm = this.fb.group({
      id: ['',0],
        nom: ['', [Validators.required]],
        prenom: ['', [Validators.required]],
        email : ['', [Validators.required,Validators.pattern(this.emailPattern)]],
        password : ['', [Validators.required,Validators.minLength(6),Validators.pattern(this.passwordPatern)]],
      cmot_de_passe : ['', [this.confirmValidator]],
      sexe: ['Masculin', [Validators.required]],
      ville:[''],
      phone:['', Validators.minLength(8)],
      phoneCode:['',[Validators.required]],

      });

    }
    initializeFormGroup() {
      this.reactiveForm.setValue({
        id: 0,
        nom: '',
        prenom: '',
        email: '',
        ville: '',
        sexe: '',
        phone: '',
        password:'',
        cmot_de_passe:'',
        phoneCode:''


      });
    }
    get mot_de_passe(){
      return this.reactiveForm.get('password');

    }
    get pwComfirm(){
      return this.reactiveForm.get('cmot_de_passe');

    }
    confirmValidator = (control: FormControl): { [s: string]: boolean } => {
      if (!control.value) {
        return { error: true, required: true };
      } else if (control.value !== this.reactiveForm.controls.password.value) {
         this.motcontrole =this.reactiveForm.controls.password.value;
        return { error: true, confirm: true };
      }
      return {};
    };

    populateForm(row: any) {
      this.reactiveForm.patchValue({
        id:row.id,
        nom:row.nom,
        prenom:row.prenom,
        email:row.email,
        ville:row.ville,
        phoneCode:row.phoneCode,
        phone:row.phone,
        adresse:row.adresse,
        password:row.password,
        cmot_de_passe:row.cmot_de_passe,
        site:row.site.id,
        video:"null null",
        photo:"null null",


      })
    }


  errorHandling(error: HttpErrorResponse) {
    return throwError(error);
  }

    insertUtilisateur(departement: Utilisateur): Observable<any> {
      console.log(departement);

      return this._http.post(this.baseUrl + "/users/add", JSON.stringify(departement))

        .pipe(catchError(this.errorHandling));

    }
    getUtilisateurById(idUtilisateur) {

      return this._http.get(this.baseUrl+"/user?id="+idUtilisateur).pipe(catchError(this.errorHandling));
         }
    getUtilisateurActif() {

      return this._http.get(this.baseUrl+"/users/find-actif").pipe(catchError(this.errorHandling));
         }
         getUtilisateurInActif() {

          return this._http.get(this.baseUrl+"/users/find-inactif").pipe(catchError(this.errorHandling));
             }


    updateUtilisateurt():Observable<any>{
      const formData = this.reactiveForm.value;
      return this._http.put<Utilisateur>(this.baseUrl + "/users/update" ,JSON.stringify(formData) ).pipe(catchError(this.errorHandling))
    }
    delUtilisateurt(idUtilisateur , visible) {
      const formData = new FormData();
      formData.append('user',idUtilisateur);
      formData.append('active',visible);
      return this._http.post(this.baseUrl + "/user/action", formData);


    }
  }


