import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';
import { RoutesModule } from './routes/routes.module';
import { AppComponent } from './app.component';
import localeFr from '@angular/common/locales/fr';
import { DefaultInterceptor } from '@core';
import { StartupService } from '@core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadService } from '@shared/myservice/file-upload.service';
import { PartenaireService } from '@shared/myservice/partenaire.service';
import { DepartementService } from '@shared/myservice/departement.service';
import { GuardGuard } from './guard.guard';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';

registerLocaleData(localeFr);
export function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    ThemeModule,
    RoutesModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    StartupService,FileUploadService,DepartementService,GuardGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
