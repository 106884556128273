import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { MatConfirmDialogComponent } from 'app/mat-confirm-dialog/mat-confirm-dialog.component';
import { DialogService } from '@shared/myservice/dialog.service';
import { MediaComponent } from 'app/evenement/media/media.component';
import { ContactMessageComponent } from 'app/evenement/contact-message/contact-message.component';
import { MessageComponent } from 'app/evenement/message/message.component';
import { HistoriqueMessageComponent } from './historique-message/historique-message.component';
import { FaqComponent } from './faq/faq.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ListCotisationComponent } from './list-cotisation/list-cotisation.component';
import { DemandesModule } from './demandes/demandes.module';





const COMPONENTS = [DashboardComponent, LoginComponent, RegisterComponent,MatConfirmDialogComponent, MediaComponent, ContactMessageComponent];
const COMPONENTS_DYNAMIC = [MatConfirmDialogComponent,MessageComponent, ListCotisationComponent];

@NgModule({
  imports: [SharedModule, RoutesRoutingModule,AngularEditorModule, DemandesModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, HistoriqueMessageComponent, FaqComponent, ListCotisationComponent],
  entryComponents: COMPONENTS_DYNAMIC,
  providers:[DialogService,]
})
export class RoutesModule {}
