import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DialogService } from '@shared/myservice/dialog.service';
import { MessageService } from '@shared/myservice/message.service';
import { UtilisateurService } from '@shared/myservice/utilisateur.service';
import { MessageComponent } from 'app/evenement/message/message.component';
import { ToastrService } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';


@Component({
  selector: 'app-historique-message',
  templateUrl: './historique-message.component.html',
  styleUrls: ['./historique-message.component.css']
})
export class HistoriqueMessageComponent implements OnInit {


 list_id_user:Array<number>=[]
  messageListe: { id: number; email: string; objet: string; message: string; }[];
  messages: Object;
  constructor(private dialogService: DialogService, private messageService:MessageService, private toastr:ToastrService,  private dialog: MatDialog,) { }
  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['receiver','subject', 'createdAt']
  @ViewChild(MatSort, {static:true}) sort: MatSort;
  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  searchKey: string;

  ngOnInit() {
    this.getMessage();
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }


  getMessage() {


    this.messageService.getMessageActif().subscribe(
      (list: any) => {
        let messageList = list.map(item => {item.visible==true
          return {
            id: item.id,
          receiver: item.receiver,
          text: item.text,
          subject: item.subject,
          createdAt: item.createdAt,
          };


        });
        setTimeout(() => {
          this.listData = new MatTableDataSource(messageList);
        }, 2000)



      },
    );

  }

/*   onDelete($key){
    this.dialogService.openConfirmDialog('Êtes-vous sûr de vouloir supprimer cete information?')
    .afterClosed().subscribe(res =>{
      if(res){
        this.messageService.d($key);
        this.toastr.warning('! Information supprimée avec succès');
      }
    });

     console.log($key);

   } */



}
