import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListDemandesComponent } from './list-demandes/list-demandes.component';
import { InactifDemandesComponent } from './inactif-demandes/inactif-demandes.component';
import { AttenteDemandesComponent } from './attente-demandes/attente-demandes.component';
import { SharedModule } from '@shared/shared.module';
import { DemandesRoutingModule } from './demandes-routing.modules';



@NgModule({
  declarations: [ListDemandesComponent, InactifDemandesComponent, AttenteDemandesComponent],
  imports: [
    CommonModule, SharedModule, DemandesRoutingModule
  ]
})
export class DemandesModule { }
