import { Injectable } from '@angular/core';
import { AppComponent } from '../../app.component';
import { HttpClient, HttpErrorResponse } from '../../../../node_modules/@angular/common/http';
import { catchError } from '../../../../node_modules/rxjs/operators';
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { Observable, throwError } from 'rxjs';
import { Message } from 'app/models/message';
import { environment } from '@env/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class MessageService {
  baseUrl: string = environment.SERVER_URL+ "/dash";
  messages=[{id:1, email:"ago@gmail.com", objet:"Salutation", message:"Bohicon keihzurhuyaze iuezhruiaezhjr ezurahj",},
  {id:2, email:"ago@gmail.com", objet:"Salutation", message:"Bohicon keihzurhuyaze iuezhruiaezhjr ezurahj",},


]
  get id(){
    return this.reactiveForm.get('id');
  }
  get text(){
    return this.reactiveForm.get('text');
  }
  get users(){
    return this.reactiveForm.get('users');
  }
  get subject(){
    return this.reactiveForm.get('subject');
  }
  get media_type(){
    return this.reactiveForm.get('media_type');
  }
  get typeMessage(){
    return this.reactiveForm.get('typeMessage');
  }
  get url(){
    return this.reactiveForm.get('url');
  }
  constructor( private _http: HttpClient) { }



  reactiveForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    subject: new FormControl(''),
    text: new FormControl('', Validators.required),
    media_type: new FormControl(''),
    url:new FormControl(''),
    users:new FormControl(''),
    typeMessage:new FormControl(''),

  });

  initializeFormGroup() {
    this.reactiveForm.setValue({
      id: null,
      subject: "",
      text: "",
      users:"",
      url:"",
      media_type:"",
      typeMessage:""
    });
  }

  errorHandling(error: HttpErrorResponse ) {
    return throwError(error);
  }
  getMessageActif() {
    return this._http.get(this.baseUrl+"/message/find").pipe(catchError(this.errorHandling));
       }
  insertMessage(message: Message): Observable<any> {
    return this._http.post(this.baseUrl + "/message/send", JSON.stringify(message))

      .pipe(catchError(this.errorHandling));

  }
}
