import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { environment } from '@env/environment.prod';
import { CotisationService } from '@shared/myservice/cotisation.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-list-cotisation',
  templateUrl: './list-cotisation.component.html',
  styleUrls: ['./list-cotisation.component.css']
})
export class ListCotisationComponent implements OnInit {

  listData: MatTableDataSource<any>;

  displayedColumns: string[] = ['motif','user','amount','factureId', 'createdAt']


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchKey: string;
  constructor(private cotisationService:CotisationService) { }

  ngOnInit() {
    this.getUtilisateurCotisation()
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  getUtilisateurCotisation() {
    this.cotisationService.getAllUtilisateurCotisation().subscribe(
      (list: any) => {

        let cotisationList = list.map(item => {item.visible==true

          return {
            id: item.id,
            motif: item.motif,
            year:item.year,
            amount: item.amount,
            state:item.state,
            factureId: item.factureId,
            createdAt:item.createdAt,
           user:item.user.nom +' '+item.user.prenom
          };
        });

        setTimeout(() => {
          this.listData = new MatTableDataSource(cotisationList);
          this.listData.sort = this.sort;
          this.listData.paginator = this.paginator;
        }, 2000)
      },
    );
  }



}
