import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { UtilisateurService } from '@shared/myservice/utilisateur.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls:['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  reactiveForm: FormGroup;

  // user : User;

  subscription: Subscription;


   checked: boolean = true;
   emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
   passwordPatern="^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$";
    motcontrole = "";
    motcont: number= 0;

    errorMessage: string="";
  constructor(public utilisateurService: UtilisateurService) {

  }
  roleUser=[{
    id:1,code:"TRST"
  }, {id:2, code:"ADMIN"}]
  ngOnInit() {
  }
  get mot_de_passe(){
    return this.reactiveForm.get('password');

  }
  get pwComfirm(){
    return this.reactiveForm.get('cmot_de_passe');

  }
  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.reactiveForm.controls.password.value) {
       this.motcontrole =this.reactiveForm.controls.password.value;
       console.log(this.motcontrole);
      return { error: true, confirm: true };
    }
    return {};
  };

  changeValue(value) {
    this.checked = !value

}
changeValuepass() : void {
  this.motcont++;
  console.log(this.motcont);
}
  genderChange(value: string): void {
    this.reactiveForm.get('sexe')!.setValue(value === 'homme' ? 'Hi, femme!' : 'Hi, lady!');
  }
   public delay(ms:number): Promise<{}> {

    return new Promise( resolve => setTimeout(resolve, ms) );
  }

 public onSubmit(){
   console.log("salut");

 }
 onClear() {
  this.reactiveForm.reset();

}
}
